@import 'src/styles/responsive';

.footer {
    padding: responsiveMax1024(20, 30) 0;
    text-align: center;
    z-index: 1;

    background-color: var(--bg-footer);

    &__container {
        display: grid;
        row-gap: 30px;
    }

    &__divider {
        height: 1px;
        border-radius: 5px;
        
        background-color: var(--text-light);
        opacity: 0.2;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        gap: 30px;

        & >*:nth-child(1) {
            margin-right: 0;
        }

        & #rights {
            order: 2;
        }

        // & >*:nth-child(3) {
        //     min-width: 230px;
        // }
    }

    &__links {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    @media screen and (min-width: 800px) {
        &__row {
            flex-direction: row;
            justify-content: space-between;

            & >*:nth-child(1) {
                margin-right: auto;
            }
    
            & >*:nth-child(3) {
                min-width: 230px;
            }

            & #rights {
                order: 0;
            }
        }

        // &__links {
        //     flex-direction: row;
        //     gap: responsive(-10, 50);
        // }
    }
}