.edit-lottery {
	display: grid;
	row-gap: 30px;

	&__fields {
		display: grid;
		row-gap: 14px;
	}

	&__btns {
		margin-top: 6px;

		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		& .btn {
			flex: 1;
		}
	}
}

.prize-list {
	padding: 10px;

	font-family: 'Montserrat';
	font-size: 14px;

	display: flex;
	flex-direction: column;
	gap: 10px;

	background-color: var(--bg-input);
	color: var(--text-white);

	border-radius: 20px;

	&__item {
		padding: 8px 10px;
		background-color: var(--bg-modal);
		border-radius: 14px;

		display: flex;
		gap: 10px;
		align-items: center;
		transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

		@media (hover: hover) {
			&:hover {
				background-color: var(--bg-modal);
				color: #fff;
			}
		}

		&-quantity {
			margin-left: auto;
		}
	}

	& svg {
		width: 21.5px;
		height: 21.5px;
	}
}

.tables {
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	&__item {
		padding: 12px;

		font-size: 14px;
		font-family: 'Montserrat';
		font-weight: 500;
		line-height: 18px;

		color: var(--text-white);

		border-radius: 14px;
		border: 2px solid rgba(255, 255, 255, 0.2);
		transition: all 0.3s ease-in-out;
		cursor: pointer;

		&--selected {
			border-color: var(--text-white);
			color: var(--text-white)
		}

		@media (hover: hover) {
			&:hover {
				border-color: var(--white);
				transform: scale(1.03);
			}
		}
	}
}