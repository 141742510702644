.help-bar {
    width: 100px;

    line-height: 0;
    overflow: hidden;
   
    display: flex;
    flex-direction: column;
    
    &__top-bar {
      height: 2px;
      border-radius: 2px;
      background: 
        linear-gradient(270deg, rgba(152, 152, 214, 0.00) 0%, #9898D6 50%, rgba(152, 152, 214, 0.00) 100%);
      transform: translateX(-50%) scaleX(2);
      animation: moveTopBar 4s ease-in-out 1s infinite;
    }
    
    &__svg {
      margin-left: calc(31px / 3);
      animation: moveSvg 4s ease-in-out 1s infinite;
    }
   
  }
  
  @keyframes moveTopBar {
    0% { transform: translateX(-50%) scaleX(2); }
    25%,
    100% { transform: translateX(50%) scaleX(2); }
  }
  
  @keyframes moveSvg {
    0% { margin-left: calc(31px / 3); }
    25%,
    100% { margin-left: calc(100% - (31px + (31px / 3))); }
  }