@import 'src/styles/responsive';

.login {
    display: grid;
    row-gap: responsiveMax1024(20, 40);

    &__form-container {
        display: grid;
        row-gap: responsiveMax1024(14, 20);
    }
}