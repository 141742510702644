/* Montserrat */

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/MontserratMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/MontserratBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

/* FuturaFuturisC */

@font-face {
    font-family: 'Futuris';
    src: url('../fonts/FuturisBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

/* Inter */

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterSemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}