.club-list {
    width: 100%;
    padding: 15px 10px;

    font-family: 'Inter';
    font-size: 14px;

    background-color: var(--bg-input);
    border-radius: 14px;
    /* max-height: 130px; */

    &__container {
        max-height: 226px;
        /* height: 226px; */

        display: flex;
        flex-direction: column;
        gap: 5px;
        
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: #E6E6F8;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--grey-01);
            border-radius: 5px;
        }
    }

    &__item {
        padding: 2px 5px;

        display: flex;
        align-items: center;
        gap: 10px;

        border: 1px solid transparent;
        border-radius: 10px;

        color: var(--text-light-half);
        transition: 0.2s ease-in-out;

        @media (hover: hover) {
            &:not(.club-list__item--null):hover {
                background-color: var(--bg-modal);
                color: #fff;
            }
        }
    }
}