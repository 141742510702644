@import 'src/styles/responsive';

// animation for form btn
// btn absolute

@keyframes scaleAndFadeInForm {
    // 0% { transform: translateY(25%) scale(0.5); opacity: 0; }
    // 30% { transform: translateY(-5%) scale(1.05); opacity: 1; }
    // 65% { transform: translateY(0%) scale(0.99); opacity: 1; }
    // 100% { transform: translateY(0%) scale(1) ; opacity: 1; }

    0% { transform: scale(0.5); opacity: 0; }
    30% { transform: scale(1.05); opacity: 1; }
    65% { transform: scale(0.99); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
}

.form {
    display: flex;
    flex-direction: column;
    gap: responsiveMax1024(30, 40);

    & .title {
        text-align: center;
    }

    &__input-grid {
        display: grid;
        row-gap: responsiveMax1024(10, 20);
    }

    @media screen and (min-width: 585px) {
        & .title {
            text-align: left;
        }

        &__input-grid {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }

        & .btn {
            width: fit-content;
        }
    }

    @media screen and (min-width: 1024px) {
        animation: scaleAndFadeInForm 0.9s linear forwards;
    }

    &--t-left {
        & .title {
            text-align: left;
        }
    }
}

.form-component {
    position: relative;
    
    margin-top: responsiveMax1024(40, 120);
    margin-bottom: responsiveMax1024(60, 100);

    display: grid;

    & .prize-view {
        display: none;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 60px;
        margin-bottom: 70px;

        grid-template-columns: repeat(2, 1fr);
        column-gap: responsive(-70, 70);
        align-items: center;

        // animation: scaleAndFadeInForm 0.9s linear forwards;

        & .prize-view {
            display: block;

            max-width: 504px;
            margin: 0 auto;

            animation: scaleAndFadeInForm 0.9s linear forwards;
        }
    }
}