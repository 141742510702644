@import 'src/styles/responsive';

.header {
    position: relative;
    height: responsiveMax1024(58, 108);
    text-align: center;

    z-index: 20;

    & a {
        z-index: 1;
    }

    & .container {
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;

        & .burger {
            margin-left: auto;
            z-index: 1;
        }
    }

    & .nav {
        display: none;

        &__item {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            
            color: var(--text-white);
            transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.02);
                    color: var(--text-orange);
                }
            }

            &--active {
                color: var(--text-orange);
            }

            &--logout {
                display: none;
            }
        }
    }

    & .mobile-menu {
        padding-top: responsiveMax1024(58, 108);
        padding-bottom: 40px;
    
        position: fixed;
        inset: 0;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        background-color: var(--bg-global);

        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;

        &--active {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
        }

        & .nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }
    }

    // text-align: left;
    // padding: 40px 0px;

    @media screen and (min-width: 850px) {
        text-align: left;

        & .burger {
            display: none;
        }

        & .nav {
            display: flex;
            align-items: center;
            gap: 20px;

            &__item {
                display: block;

                &--logout {
                    margin: 0;
                    margin-left: auto;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        & .container {
            justify-content: flex-start;
        }
    }
}