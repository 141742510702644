.select-clubs {
    display: grid;
    row-gap: 30px;

    &__fields {
        display: grid;
        row-gap: 14px;
    }

    &__btns {
        margin-top: 6px;

        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        & .btn {
            flex: 1;
        }
    }
}