@import 'src/styles/responsive';

.prize-view {
    position: relative;
    width: 100%;

    aspect-ratio: 1/1;

    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 0;

    &__date {
        position: absolute;

        right: responsiveMax1024(20, 30);
        top: responsiveMax1024(77, 87);

        font-family: 'Montserrat';
        font-weight: 500;
        font-size: responsiveMax1024(10, 14);

        color: rgba(255, 255, 255, 0.8);
    }

    // & img {
    //     width: 100%;
    // }
}