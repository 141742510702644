.search {
    position: relative;
    
    & .search-icon {
        height: 18px;
        width: 18px;

        display: block;

        position: absolute;
        top: 50%;
        transform: translateY(-52%);
        left: 18px;

        z-index: 5;
        pointer-events: none;
    }

    & input {
        padding-left: 46px;
    }
}