@import 'src/styles/responsive';

// animation for confetti

@keyframes scaleAndFadeInConfetti {
    0% { width: 65%; height: 62.5%; opacity: 0; }
    30% { width: 140%; height: 135%; opacity: 1; }
    65% { width: 128%; height: 123%; opacity: 1; }
    100% { width: 130%; height: 125%; opacity: 1; }
}

@keyframes scaleAndFadeOutConfetti {
    0% { width: 130%; height: 125%; opacity: 1; }
    100% { width: 150%; height: 145%; opacity: 0; }
}


// animation for form btn
// btn absolute

@keyframes scaleAndFadeInBtnAbsolute {
    0% { transform: translateY(calc(45px + 100%)) scale(0.5); opacity: 0; }
    30% { transform: translateY(calc(29px + 100%)) scale(1.05); opacity: 1; }
    65% { transform: translateY(calc(30px + 100%)) scale(0.99); opacity: 1; }
    100% { transform: translateY(calc(30px + 100%)) scale(1) ; opacity: 1; }
}

// btn relative

@keyframes scaleAndFadeInBtnRelative {
    0% { transform: translateY(15px) scale(0.5); opacity: 0; }
    30% { transform: translateY(-1px) scale(1.05); opacity: 1; }
    65% { transform: translateY(0px) scale(0.99); opacity: 1; }
    100% { transform: translateY(0px) scale(1); opacity: 1; }
}

// btn fade out

@keyframes fadeOutAndScaleBtn {
    from { transform: scale(1); opacity: 1; }
    to { transform: scale(0.5); opacity: 0; }
}

// animation for prize image

@keyframes scaleAndFadeInImage {
    0% { transform: translate(-50%, -50%) scale(0.5); opacity: 0; }
    30% { transform: translate(-50%, -50%) scale(1.05); opacity: 1; }
    65% { transform: translate(-50%, -50%) scale(0.99); opacity: 1; }
    100% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
}

// animation for wheel

@keyframes smoothBlur {
    0% { filter: blur(0px); }
    50% { filter: blur(1px); }
    0% { filter: blur(0px); }
}

@keyframes fadeOutAndScaleWheel {
    from { transform: scale(1); opacity: 1; }
    to { transform: scale(0.5); opacity: 0; }
}

.wheel-component {
    position: relative;

    margin-top: responsiveMax1024(40, 120);
    margin-bottom: responsiveMax1024(78, 139);

    &__header {
        position: relative;
        z-index: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: responsiveMax1024(20, 40);
    
        & .title {
            text-align: center;

            opacity: 0;
            animation: fadeIn 0.4s ease-in forwards;
        }
    
        & .text {
            max-width: 503px;
            text-align: center;

            opacity: 0;
            animation: fadeIn 0.4s ease-in forwards;

            & br {
                display: none;
            }
        }
    
        & .help-bar {
            display: flex;

            position: absolute;
            bottom: calc((responsiveMax1024(30, 40) + 30px) * -1);

            opacity: 0;
            animation: fadeIn 0.4s linear forwards;
        }

        & .btn {
            position: absolute;
            bottom: 0;
            transform: translateY(calc(responsiveMax1024(30, 40) + 100%)) scale(0.5);

            opacity: 0;
            animation: scaleAndFadeInBtnAbsolute 0.9s linear forwards;
        }
    }

    &__btn {
        display: none;
    }

    &__btn-mobile {
        position: relative;
        z-index: 10;
    }

    & .prize-view {
        position: absolute;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;

        max-width: 504px;

        z-index: 1;

        animation: scaleAndFadeInImage 0.9s linear forwards;
    }


    & .wheel-container {
        position: relative;
        margin-top: responsiveMax1024(83, 123.5);
    }

    @media screen and (min-width: 501px) {
        &__header {
            & .text {
                & br {
                    display: inline;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: responsive(-70, 70);

        margin-top: 60px;
        margin-bottom: 100px;

        &__header {
            align-items: flex-start;
            justify-content: center;

            & .title {
                text-align: left;
            }
        
            & .text {
                text-align: left;
            }
        
            & .help-bar {
                display: none;
            }
    
            & .btn {
                position: relative;
                opacity: 1;
                transform: translateY(0px) scale(1);
                animation: none;
            }
        }

        &__btn {
            display: block;
        }

        &__btn-mobile {
            display: none;
        }

        & .wheel-container {
            margin-top: 0;
        }
    }

}

.wheel-container {
    position: relative;

    max-width: 590px;
    margin: 0 auto;

    z-index: 0;

    &__gradient {
        width: 100%;

        aspect-ratio: 2290 / 1824;

        display: block;
        position: absolute;

        top: 50%;
        left: 50%;

        pointer-events: none;
        background: radial-gradient(45% 43.48% at 50.04% 50.05%, #661FFF 0%, #572FC3 35.31%, #301E63 65%, #231647 82.19%, #1D123B 100%);

        transform: translate(-50%, -50%) scale(2.3) rotate(20deg);
    }

    &__figure {
        position: absolute;
        height: fit-content;

        z-index: 0;

        &--top-right {
            width: 30%;

            top: -15%;
            right: 7%;
        }

        &--top-left {
            width: 21%;

            top: -2%;
            left: 5%;
        }

        &--bottom-left {
            width: 35%;
            
            bottom: -1%;
            left: -18%;
        }
    }
    
    &__confetti {
        position: absolute;
        
        left: 50%;
        top: 50%;
        z-index: 15;

        width: 65%;
        height: 62.5%;
        transform: translate(-51%, -52%);
        opacity: 0;

        animation: scaleAndFadeInConfetti 0.9s ease-out forwards;

        &--fade-out {
            animation: scaleAndFadeOutConfetti 0.4s ease-in-out forwards;
        }
    }
}

.wheel {
    position: relative;
    border-radius: 50%;

    &--fade-out {
        animation: fadeOutAndScaleWheel 0.4s ease-in-out forwards;
    }

    &--hide {
        opacity: 0 !important;
        pointer-events: none !important;
    }

    & img {
        max-width: none;
        max-height: none;
    }

    &__touch-container {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;

        width: 100%;
        height: 100%;

        transform: translate(-50%, -50%);
        z-index: 15;

        &--pe-none {
            pointer-events: none;
        }
    }

    &__container {
        line-height: 0;
        margin: 0 auto;
        position: relative;
        z-index: 10;
    
        max-width: 590px;
    }

    &__static {
        width: 100%;
        inset: 0 0 0 0;
    }

    &__spinner {
        position: absolute;
        inset: 0 0 0 0;
        z-index: -2;

        width: 100%;
        height: 100%;
    
        filter: blur(0px);
        transition: filter 0.15s ease-in-out;
    }

    &__spinner-animation_0_3 {
        transition: transform 0.3s ease-out;
    }

    &__spinner-animation_3_5 {
        animation: smoothBlur 3.5s ease-in-out forwards;
    
        transition:
            transform 3.5s ease-out;
    }

    &__shadow-one {
        position: absolute;
        top: -7%;
        left: -5%;
        z-index: -1;

        width: 114%;
        height: 114%;
    }

    &__shadow-two {
        position: absolute;
        top: -7.1%;
        left: -7%;
        z-index: -1;

        width: 114%;
        height: 114%;
    }

    &__arrow {
        position: absolute;
        top: 6%;
        left: -5%;
        
        width: 32%;
        height: 32%;
    }
}