.edit-clubs {
    display: grid;
    row-gap: 30px;

    &__meme {
        display: grid;
        row-gap: 10px;
        align-items: center;

        text-align: center;

        & img {
            width: 55%;
            border-radius: 14px;
            box-shadow: 0 5px 10px 1px rgba(0,0,0,0.1);
        }
    }

    &__fields {
        display: grid;
        row-gap: 14px;
    }

    &__btns {
        margin-top: 6px;

        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        & .btn {
            flex: 1;
        }
    }
}