.btn-icon {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    
    line-height: 0;

    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;

    & svg {
        width: 24px;
        height: 24px;
        transition: transform 0.2s ease-in-out;
    }

    @media (hover: hover) {

        &:hover {
            & .fill-color {
                fill: var(--text-orange);
            }

            & .stroke-color {
                stroke: var(--text-orange);
            }

            svg {
                transform: scale(1.12);
            }
        }

    }

    &--disabled {
        cursor: not-allowed;

        @media (hover: hover) {

            &:hover {
                & .fill-color {
                    fill: var(--text-white);
                }

                & .stroke-color {
                    stroke: var(--text-white);
                }
               
                & svg {
                    transform: scale(1);
                }
            }
            
        }
    }

    &--sm svg {
        width: 20px;
        height: 20px;
    }
    
}

.fill-color {
    fill: var(--text-white);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.stroke-color {
    stroke: var(--text-white);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
