@import 'src/styles/responsive';
@import 'src/styles/animations';

.disable-scroll {
    overflow: hidden !important;
    touch-action: none !important;
    
    /* Safari */
    -webkit-overflow-scrolling: none !important;
    /* Other browsers */
    overscroll-behavior: none !important;
}


body {
    position: fixed;
    inset: 0;
}

.app {
    min-height: 100vh;
    // background: var(--color-blue-dark);

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    overflow-x: hidden;
    overflow-y: hidden;
    
    background-color: var(--bg-global);

    // & > * { border: 2px solid red; }
}

/* layout styles */

.container {
    position: relative;
    
    max-width: 1440px;
    width: 100%;

    margin: 0 auto;
    // padding: 0 responsive(15, 120, 'mobile-adaptive');
    padding: 0 responsiveMax1024(15, 40);
    
    @media screen and (min-width: 1024px) {
        padding: 0 responsive(-150, 120);
    }
}

/* common styles */

.title {
    font-family: 'Futuris';
    font-size: responsiveMax1024(24, 40);
    font-weight: 700;
    line-height: responsiveMax1024(28, 40);
    text-transform: uppercase;
    color: var(--text-white);

    /* multi-color with span */

    & span {
        color: var(--text-orange);
    }

    &--sm {
        font-size: responsiveMax1024(18, 26);
        line-height: responsiveMax1024(22, 32);
    }
}

.text {
    font-family: 'Inter';
    font-size: responsiveMax1024(16, 24);
    font-weight: 400;
    line-height: responsiveMax1024(20, 32);
    color: var(--text-light);
}

.link {
    font-family: 'Inter';
    font-size: responsiveMax1024(12, 14);
    font-weight: 400;
    line-height: 18px;
    color: var(--text-light);

    // @media (hover: hover) {
    //     &:hover {
            
    //     }
    // }

    &--center {
        text-align: center;
    }
}

.btn {
    // height 50 -> 60
    padding:
        responsiveMax1024(15, 20)
        responsiveMax1024(18, 38);

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: var(--text-white);

    border: 2px solid transparent;
    border-radius: 30px;
    background-color: var(--text-orange);

    cursor: pointer;
    
    transition:
        background 0.3s ease-in-out,
        opacity 0.3s ease-in-out,
        border 0.3s ease-in-out,
        width 0.3s ease-in-out;
        
    &[disabled] {
        opacity: 0.5 !important;
    }

    @media (hover: hover) {
        &:hover {
            background-color: var(--color-dark-orange);
        }
    }

    &--light {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.2);

        @media (hover: hover) {
            &:hover {
                background-color: transparent;
                border-color: rgba(255, 255, 255, 1);
            }
        }
    }

    &--social {
        padding: 0;

        width: 50px !important;
        height: 50px !important;

        line-height: 0;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--with-icon {
        display: flex;
        align-items: center;
        gap: 10px;

        & path {
            stroke: #fff;
        }
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    gap: responsiveMax1024(5, 10);

    &--checkbox {
        gap: 10px;
    }
}

.label {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    color: var(--text-light);

    &--req::after {
        margin-left: 5px;
        content: '*';
        color: var(--text-orange);
    }

    @media screen and (min-width: 1024px) {
        font-weight: 400;
        color: var(--text-white);
    }

    &--error {
        color: var(--text-orange);
    }
}

.input {
    width: 100%;
    // height 50px -> 60px
    padding: responsiveMax1024(13.25, 18.25) 18px;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px; /* 112.5% */
    color: var(--text-white);
    caret-color: var(--text-white);
    
    border: 2px solid var(--bg-input);
    border-radius: 14px;
    background-color: var(--bg-input-active);

    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;

    &:placeholder-shown {
        color: var(--text-light-half);
        background-color: var(--bg-input);
    }

    @media (hover: hover) {
        &:hover {
            border-color: var(--input-border-color);
        }
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: var(--text-white);
        box-shadow: 0 0 0px 40rem var(--bg-input-active) inset;
        border: 2px solid var(--bg-input);
        

        transition:
            background-color 9999s ease-in-out 0s,
            box-shadow 0.3s ease-in-out,
            color 0.3s ease-in-out,
            border-color 0.3s ease-in-out;

        @media (hover: hover) {
            &:hover {
                border-color: var(--input-border-color);
            }
        }
    }

    &[error=true] {
        border-color: var(--input-border-color-error);
    }
}

.overlay {
	height: 100vh;
    position: fixed;
    
	top: 0;
	left: 0;
	right: 0;

    z-index: 50;
	opacity: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    
	overflow: hidden;
	overflow-y: auto;
    
    background-color: rgba(0,0,0,0.6);
    animation: fadeIn 0.1s ease-in 1 forwards;
}