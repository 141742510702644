.burger {
    width: 30px;
    height: 30px;
    position: relative;
    background-color: var(--bg-modal);
    padding: 4px;
    border-radius: 50%;

    cursor: pointer;

    & svg {
        stroke: var(--text-white);
        transition: translate 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    @media (hover: hover) {
        &:hover svg {
            stroke: var(--text-orange);
            transform: scale(1.12);
        }
    }

    &__line {
        transition: 0.3s ease-in-out;
        stroke-dasharray: 60 31 47 1000;
    }

    &--active svg {
        translate: 1.2px -1.3px;
        rotate: 0.125turn;

        & .burger__line {
            stroke-dasharray: 60 105 60 1000;
            stroke-dashoffset: -90;
        }
    }
}