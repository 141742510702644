@import 'src/styles/responsive';

// select animations

@keyframes selectListSlideDownAndFadeIn {
    0% { transform: translateY(-50%) scaleY(0); opacity: 0; }
    100% { transform: translateY(0) scaleY(1); opacity: 1; }
}

@keyframes selectArrowRotate180 {
    0% { transform: rotateX(0deg); }
    100% { transform: rotateX(180deg); }
}

.select {
    position: relative;
    z-index: 1;

    & .search-icon {
        display: none;
    }

    &__arrow {
        position: absolute;
        right: 20px;
        top: responsiveMax1024(23, 28);
        z-index: 2;

        pointer-events: none;
        
        & path {
            stroke: var(--text-light);
            transition: 0.3s ease-in-out;
        }
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;

        padding: 20px;
        padding-top: responsiveMax1024(65, 76);
        padding-bottom: responsiveMax1024(15, 20);

        display: flex;
        align-items: flex-end;

        border-radius: 14px;
        background-color: var(--bg-input);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);

        animation: selectListSlideDownAndFadeIn 0.2s linear;
    }

    &__list {
        width: 100%;
        max-height: 190px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__option {
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: var(--text-light);
        opacity: 0.5;

        cursor: pointer;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        transition:
            color 0.3s ease-in-out,
            opacity 0.3s ease-in-out;

        @media (hover: hover) {
            &:hover {
                color: var(--text-white);
                opacity: 1;
            }
        }
        
        &--active {
            color: var(--text-white);
            opacity: 1;
        }

        &--disabled {
            @media (hover: hover) {
                &:hover {
                    cursor: auto;
                    color: var(--text-white);
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 2px;
        }
    }

    & input {
        position: relative;

        width: 100%;
        // height 50px -> 60px
        padding: responsiveMax1024(13.25, 18.25) 18px;
        padding-right: 40px;

        z-index: 1;

        font-family: 'Inter';
        font-size: 16px;
        font-weight: 400;
        line-height: 1; /* 112.5% */
    
        border-radius: 14px;
        border: 2px solid transparent;
        background-color: var(--bg-input);

        color: var(--text-white);
        transition:
            color 0.3s ease-in-out,
            background-color 0.3s ease-in-out,
            border-color 0.3s ease-in-out;
            
        cursor: pointer;

        // HOVER
        @media (hover: hover) {
            &:hover {
                border-color: var(--input-border-color);
            }
        }

        &:placeholder-shown {
            color: var(--text-light-half);
        }
    }

    &--active {
        z-index: 2;

        & input {
            background-color: var(--bg-input-active) !important;
        }
        
        & .select__arrow {
            animation: selectArrowRotate180 0.2s ease-in-out forwards;
        }
    }

    &--selected {
        & input {
            color: var(--text-white);
            background-color: var(--bg-input-active);
        }
    }

    &--error input {
        border-color: var(--input-border-color-error);
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &--search {
        & .search-icon {
            height: 18px;
            width: 18px;

            display: block;

            position: absolute;
            top: 50%;
            transform: translateY(-52%);
            left: 18px;

            z-index: 5;
            pointer-events: none;
        }

        & input {
            padding-left: 46px;
        }
    }
}
