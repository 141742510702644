.check-box {
    display: flex;
    gap: 10px;

    & svg {
        cursor: pointer;
        min-width: 20px;
        min-height: 20px;

        & path {
            transition: stroke-dashoffset 0.3s ease-in-out;
        }
    }

    label {
        margin-top: 1px;
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px; /* 128.571% */

        color: var(--text-light);

        & a {
            color: var(--text-orange);
            text-decoration-line: underline;
        }
    }
}