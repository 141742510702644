@import 'src/styles/responsive';

:root {
    /* device sizes */

    // --tablet-w: 1000px;
    // --mobile-w: 600px;

    /* header */

    // --header-h: clamp(60px, calc(60px + (28 + 28 * 0.7) * ((100vw - 320px) / 1440)), 88px);
    // --header-z: 10;

    /* main colors */ 

    --text-white: #FFFFFF;
    --text-light: #AFAFE8;
    --text-light-half: rgba(175, 175, 232, 0.5);
    --text-orange: #ED692A;
    --color-dark-orange: #C6511A;

    --bg-global: #1D123B;
    --bg-footer: #130C28;
    --bg-modal: #23154D;

    --bg-input: #2C1864;
    --bg-input-active: #432599;
    --input-border-color: #6D63A6;
    --input-border-color-error: #D96060;
    // --color-orange: #ED692A;
    // --color-orange-dark: #C6511A;
    // --color-blue-dark: #12123B;
    // --color-grey-01: #676785;

    --grey-01: #74768d;
    --grey-03: #ccd1e4;
    // --grey-04: #f9f9f9;

    /* text colors */

    // --text-purple: #5E5EB8;
    // --text-purple-light: #C3C3FF;

    /* bg colors */

    // --bg-dark: #0C0C2C;
    // --bg-dark-medium: #281D68;
    // --bg-dark-light: #6159BC;

    // @media screen and (min-width: 769px) {
    //     --header-h: clamp(60px, calc(60px + 28 * (100vw / 1440)), 88px);
    // }

}

html * {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    // border: 1px solid red;
}

html,
body {
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.no-touch-scroll {
    touch-action: none;
    -ms-touch-action: none;
    overflow: hidden;
}

// .scroll-mt {
//     scroll-margin-top: responsive(75, 108, 'mobile-adaptive');

//     @media screen and (min-width: 769px) {
//         scroll-margin-top: responsive(75, 108)
//     }
// }

// .flex--c-c {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .flex-v--c-c {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

// .flex--sb-c {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

// .flex-v--sb-s {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
// }