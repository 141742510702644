@import 'src/styles/responsive';

.block {
    position: relative;
    margin: 0 auto;
    
    padding: responsiveMax1024(15, 40);
    padding-top: 39px;
    padding-bottom: 40px;

    border-radius: 20px;
    background-color: var(--bg-modal);

    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

    &__close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    &--400 { max-width: 400px; }
    &--500 { max-width: 400px; }
    &--600 { max-width: 400px; }

    @media screen and (min-width: 1024px) {
        padding-top: 49px;
    }
}